<template>
  <div class="ev2-box dash-realtime-onlines" :class="{expanded: expanded, 'no-expanded': !expanded}">
<!--    <div class="header">
      <div class="hcol1">
        <div class="title">Marketplace</div>
        <div class="subtitle">Informações sobre o anúncio do leilão</div>
      </div>
      <div class="hcol2">
      </div>
    </div>-->
    <div v-if="expanded" class="text-right m-b-sm wrapper-md flex justify-between items-center">
      <div>
        <erp-s-field view="ll" label="Pesquisar" class="e-input-modern size1">
          <erp-input v-model="search" />
        </erp-s-field>
      </div>
      <div><span v-if="users && users.length > 0" class="online">{{users.length}} pessoas conectadas no auditório.</span></div>
      <a @click="expanded = false">Minimizar janela</a>
    </div>
    <div class="body no-overflow body-users hoverable" :class="{expanded: expanded, 'no-expanded': !expanded}">
      <div v-if="loading" class="flex items-center non-selectable"><sl-loading class="size-18" content="" /> <span class="m-l-sm">Verificando quem está online...</span> </div>
      <div v-if="!loading && users" class="usersOnline" @click="expand">
        <div v-for="(user, key) in limitedUsers" :key="key" class="user" @click="userClick(user)">
          <u-tooltip v-show="!expanded" :offset="[5,5]">{{user.person?.name}} ({{user.username}})</u-tooltip>
          <div class="app-person-avatar m-r-sm flex justify-center full-width">
            <img v-if="user.person && user.person.photo" :src="user.person.photo">
            <div v-else class="img-fake">{{ firstLettersName(user.person?.name) }}</div>
          </div>
          <div class="user-details" v-if="expanded">
            <div class="name">{{user.person?.name}}</div>
            <div class="username">{{user.username}}</div>
            <div class="opts m-t non-selectable">
              <div><a class="disabled"><i class="fa fa-video-camera text-grey" /> Iniciar video-chamada</a> </div>
              <div class="m-t-xs"><a class="disabled"><i class="fa fa-face-viewfinder text-grey" /> Pedir validação facial</a> </div>
              <div class="m-t-xs"><a class="disabled"><i class="fa fa-message text-grey" /> Chat</a> </div>
              <div class="m-t-xs"><a class="disabled"><i class="fa fa-ban text-negative" /> Bloquear</a> </div>
            </div>
          </div>
        </div>
        <template v-if="!expanded">
        <div v-if="users && users.length > 1" class="online">{{users.length}} pessoas conectadas no auditório.</div>
        <div v-if="users && users.length === 1" class="online">{{users.length}} uma pessoa online.</div>
        <div v-if="!users || !users.length" class="online">Ninguém online no auditório deste leilão até o momento.</div>
        </template>
      </div>
      <div v-if="!loading" class="usersOnlineOpts">
        <div>
          <label for="checkFalar" class="flex items-center hidden-nohover non-selectable">
            <input type="checkbox" id="checkFalar" v-model="habilitarFala" color="blue-grey" />
            <span class="m-l-xs font-11">Falar</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import SlLoading from "components/layout/components/Loading.vue"
import {realtimeOnlines} from "@/domain/stats/service"
import {UTooltip} from "uloc-vue"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import arrematanteWindow from "components/arrematante/windows/arrematante"
import {findByPerson} from "@/domain/arrematante/services"

export default {
  name: "RealtimeOnlines",
  data () {
    return {
      loading: true,
      habilitarFala: true,
      expanded: false,
      search: null,
      users: []
    }
  },
  mounted() {
    this.$interval && clearInterval(this.$interval)
    const intervalId = setInterval(() => {
      if (this.comunicator) {
        if (this.comunicator._interceptors.indexOf(this.bindMessage) === -1) {
          this.comunicator._interceptors.push(this.bindMessage)
        }
        clearInterval(intervalId)
      }
    }, 300)
    this.$interval = setInterval(() => {
      this.load()
    }, 30000)
    this.load()
  },
  beforeDestroy() {
    this.$interval && clearInterval(this.$interval)
    this.comunicator._interceptors.splice(this.comunicator._interceptors.indexOf(this.bindMessage), 1)
  },
  computed: {
    limitedUsers () {
      let filteredUsers = [...this.users]

      // Filtra os usuários se houver um valor digitado em search
      if (this.search) {
        const searchTerm = this.search.toLowerCase()
        filteredUsers = filteredUsers.filter(user => {
          const nameMatch = user.person.name.toLowerCase().includes(searchTerm)
          const usernameMatch = user.username.toLowerCase().includes(searchTerm)
          return nameMatch || usernameMatch
        })
      }
      if (this.expanded) {
        // Ordena os resultados filtrados por user.person.name
        filteredUsers.sort((a, b) => {
          const nameA = a.person.name.toLowerCase()
          const nameB = b.person.name.toLowerCase()
          return nameA.localeCompare(nameB)
        })
      }

      // Se expanded for true, retorna todos os usuários filtrados, senão retorna apenas os primeiros 40
      return this.expanded ? filteredUsers : filteredUsers.slice(0, 40)
    }
  },
  components: {
    ErpInput,
    ErpSField,
    SlLoading,
    UTooltip,
    //ErpCheckbox
    //UCheckbox
  },
  mixins: [tabMixin],
  methods: {
    expand () {
      this.expanded = true
    },
    bindMessage (e) {
      try {
        const event = JSON.parse(e.data)
        if (event && event.type && event.type === 'joinRoom') {
          if (event.roomId !== 'leilao-' + this.leilao.id) return
          if (!Array.isArray(this.users)) {
            this.users = []
          }
          if (this.users.length) {
            if (this.users.find(u => u.id === event.data.id)) return
          }
          this.users.push(event.data)
          this.habilitarFala && this.falar(event.data.username + ' conectou', 1.6)
        }
        if (event && event.type && event.type === 'leaveRoom') {
          if (!Array.isArray(this.users) || !this.users.length) return
          this.users.splice(this.users.indexOf(this.users.find(u => u.id === event.data.id)), 1)
        }
      } catch (e) {
        console.error(e)
      }
    },
    load () {
      realtimeOnlines('leilao-' + this.leilao.id)
          .then(response => {
            /*const desiredLength = 500;
            while (response.data.length < desiredLength) {
              response.data.push(...response.data.slice(0, desiredLength - response.data.length));
            }*/
            this.users = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            console.log(error)
          })
    },
    userClick (user) {
      if (!this.expanded) return
      user.person && user.person.id && this.loadArrematanteByPerson(user.person.id)
    },
    loadArrematanteByPerson (id) {
      findByPerson(id)
          .then(response => {
            this.arrematanteWindow(response.data.id)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    arrematanteWindow
  }
}
</script>
