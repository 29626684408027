<template>
  <div class="ev2-box req-atencao hscroll">
    <div class="header">
      <div class="hcol1">
        <div class="title">Requer atenção</div>
        <div class="subtitle">Análises de inteligência nos dados</div>
      </div>
      <div class="hcol2">
      </div>
    </div>
    <div class="body m-t-lg" v-if="page.loadingStats">
      <u-fake-loader />
      <u-fake-loader width="50%" />
      <u-fake-loader width="90%" />
      <br>
      <br>
      <br>
      <u-fake-loader />
    </div>
    <div class="body m-t-md" v-else style="flex: 1; max-height: 300px">
      <div v-if="page.stats.requerAtencao && page.stats.requerAtencao.length">
      <requer-atencao-item v-for="(alert, key) in page.stats.requerAtencao" :key="key" :alerta="alert" />
      </div>
      <div v-else>
        Nenhum alerta até o momento
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import {UFakeLoader} from "uloc-vue"
import RequerAtencaoItem from "components/leiloes/components/dashboard/includes/RequerAtencaoItem.vue"

export default {
  name: "RequerAtencao",
  components: {
    RequerAtencaoItem,
    // ECol,
    // ERow,
    UFakeLoader
  },
  mixins: [tabMixin],
  computed: {
  },
  methods: {}
}
</script>
