<script>
import AlertaCliente from "components/leiloes/components/dashboard/includes/requerAtencao/Cliente.vue"
import AlertaLance from "components/leiloes/components/dashboard/includes/requerAtencao/Lance.vue"

export default {
  name: "RequerAtencaoItem",
  components: {AlertaLance, AlertaCliente},
  props: {
    alerta: {required: true}
  }
}
</script>

<template>
  <section class="cont-itensAtencao flexC-base">
    <alerta-cliente v-if="alerta.tipo === 'cliente'" :alerta="alerta" />
    <alerta-lance v-if="alerta.tipo === 'lance'" :alerta="alerta" />
  </section>
</template>

<style scoped lang="stylus">

</style>
