<script>
import mixin from './mixin'
import loteWindow from 'components/leiloes/windows/lote'
export default {
  name: "AlertaLance",
  mixins: [mixin],
  methods: {
    loteWindow
  }
}
</script>

<template>
  <a @click="loteWindow({id: alerta.loteId})" class="item-unico">
  <div class="c-left">
    <span>{{ firstLettersName(alerta.nome) }}</span>
  </div>
  <div class="c-right flexC-base">
    <div class="nome">{{ alerta.nome }}</div>
    <div class="apelido">{{ alerta.apelido }}</div>
    <div class="lote">LOTE {{ alerta.loteNumeroString || alerta.loteNumero }}</div>
    <p v-if="alerta.motivo === 'lance-acima-mercado'" class="text-blue">
      Efetuou lance {{ Math.round(Number(alerta.porcentagemLance)) }}% acima do valor de mercado.
    </p>
  </div>
</a>
</template>

<style scoped lang="stylus">

</style>
