<script>
import mixin from './mixin'
import arrematanteWindow from "components/arrematante/windows/arrematante"
export default {
  name: "AlertaCliente",
  mixins: [mixin],
  methods: {
    arrematanteWindow
  }
}
</script>

<template>
  <a @click="arrematanteWindow(alerta.id)" class="item-unico">
  <div class="c-left">
    <span class="bg-orange-10">{{ firstLettersName(alerta.nome) }}</span>
  </div>
  <div class="c-right flexC-base">
    <div class="nome">{{ alerta.nome }}</div>
    <div class="apelido">{{ alerta.apelido }}</div>
    <div class="lote" v-if="alerta.lotesNumeros">LOTE: {{ alerta.lotesNumeros.split(',').join(', ') }}</div>
    <p v-if="alerta.motivo === 'primeira-tentativa-compra'">
      Efetuou lance em {{alerta.totalLotes}} lotes mas nunca fez nenhuma compra.
    </p>
    <p v-if="alerta.motivo === 'primeira-compra'" class="text-negative">
      Arrematou {{alerta.totalCompras}} lotes mas nunca fez nenhuma outra compra.
    </p>
  </div>
</a>
</template>
